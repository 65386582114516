import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Welcome from "./pages/Welcome/Welcome";
import QrCode from "./pages/qrCode/QrCode";

const App = () => {
  return (
    <>
      <Navbar />
      <Router>
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route path="/qr-code" element={<QrCode />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;