import "./navbarStyles.scss";

const navbar = () => {
  return (
    <>
      <section className="navbar w-100">
        <img
          src={process.env.PUBLIC_URL + "images/logo.png"}
          alt="Patrik Birula"
          className="logo"
        />
      </section>
    </>
  );
};

export default navbar;
